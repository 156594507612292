'use client'

import { Inter } from 'next/font/google'

import * as Sentry from '@sentry/nextjs'
import { useEffect } from 'react'

import PrimaryLogo from '@/images/logo/logo-primary.svg'

const inter = Inter({
    subsets: ['latin'],
    display: 'optional',
    variable: '--font-inter',
})

export default function GlobalError({ error }) {
    useEffect(() => {
        Sentry.captureException(error)
    }, [error])

    return (
        <html>
            <body className={`${inter.variable} ${inter.className}`}>
                <div className={'error-wrapper'}>
                    <PrimaryLogo className={'error-logo'} />
                    <div className={'error-white-wrapper'}>
                        <div className={'error-content-wrapper'}>
                            <img src="/images/error.png" alt="Error" className={'error-image'} />
                            <h1 className={'error-title'}>Whoops, something went wrong.</h1>
                            <div className={'error-message'}>
                                We're sorry, but something went wrong. We've been notified about this issue and we'll
                                take a look at it shortly. In the meantime, try refreshing the page and see if the issue
                                persists.
                            </div>
                        </div>
                    </div>
                </div>
            </body>
        </html>
    )
}
